.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

* {
  font-family: 'Roboto Condensed', sans-serif;
}

.header {
  background-color:/*#2997ff;*/#096163; 
  color:#fff;
  padding:20px;
  font-size:30px;
}

.header.loading {
  background-color:#096163; /*#2997ff;*/
  color:#fff;
  padding:20px;
  font-size:30px;
  font-weight:bold;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.upload {
  background-color: #096163; /*rgba(41, 151, 255,1);*/
  color:#fff;
  text-align:center;
  width:100%;
  max-width: 100%;
  margin:0px;
  display:block;
  transition:all .5s;
}
.upload.initial {
  /*
  background-color:#096163; 
  width:100%;
  max-width:500px;
  margin: 100px auto;
  text-align:center;*/
}


.upload button {
  margin-bottom:40px;
  border: none;
  padding:10px;
  background-color:rgba(255,255,255,0.2);
  color:#fff;
  font-size:1rem;
}


.upload .droparea {
  border: 2px dashed rgba(255,255,255,0.3);
  color:rgba(255,255,255,0.8);
  display:inline-block;
  padding:10px;
  margin:30px;
  font-size:17px;
  border-radius:5px;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.dropzone {
  border: 0px solid rgba(0,0,0,0);

}
.dropzone:focus{ border: none; outline:none; }
.dropzone input:focus,button:focus{ outline:none; }
.upload button:hover { 
  cursor:pointer;
}
.dropzone .inner {
  box-sizing:border-box;
  border-radius:0px;
  height: 100%;
  width:100%;
  position:fixed;
  top:0;
  left:0;
  z-index:1000;
  text-align:center;
  background-color:rgba(255,255,255,0.0);
  transition: all 0.5s;
  padding:0px;


  height:calc(100% - 50px);
  width:calc(100% - 50px);
  border-radius:5px;
  margin:25px;
  box-sizing:border-box;
  border: 3px dashed #ccc;
  display:block;
  top:0;
  z-index:1000;
  text-align:center;
  font-weight:bold;
  color:#666;
  
  background-color:rgba(255,255,255,0.8);
  
  transition: all 0.5s;
  padding:0px;
}
.dropzone .inner.hidden {
  z-index:-1;
  opacity:0;
}
div.blur {
  filter:blur(8px);
}
/*
.dropzone {
  box-sizing:border-box;
  margin:30px;
  border-radius:0px;
  border: 3px dashed #ccc;
  height: 500px;
  text-align:center;
  background-color:#eee;
  transition: all 0.5s;
  overflow:hidden;
  line-height:500px;
  padding:0px;
}
*/

.dropzone.disable {
  height:0px; border:0px;
  display:none;
  /*
  height:45px;
  line-height:40px;
  /* … 
  border-style:solid;
  animation: 2s rotate linear infinite;*/
}
.dropzone.disable .inner {
  display:none; /* none */ 
}
.upload.disable .loading {
  display:block;
}
.upload .loading {
  display:none; /* none */ 
  position:relative;
  height:183px;
  box-sizing:border-box;
  padding-top:70px;
  
}

@keyframes loadbubble {
  from {
    transform: translate(0%, -20%);
    background-color:rgba(255,255,255,0);
  }
  25% {
    transform: translate(100%, -20%);
    background-color:rgba(255,255,255,1);
  }
  50% {
    transform: translate(200%, -20%)
  }
  75% {
    transform: translate(300%, -20%)
  }
  100% {
    transform: translate(400%, -20%);
    background-color:rgba(255,255,255,0);
  }
}
.upload .loading .text { 
  color:#fff;
  font-size:15px;
  bottom:10px;
  display:block;
  margin: auto;
}
.upload .loading .waves {
  height:50px;
  position:relative;
}
.upload .loading .waves .wave {
  display:block;
  background-color:#fff;
  width:12px; height:2px;
  /*border-radius:100%;*/
  position:absolute;
  top:30px;
  left:calc(50% - 30px);
  pointer-events:none;
  background-color:rgba(255,255,255,0);
  animation: loadbubble 3s linear infinite;
}
.upload .loading .wave:nth-child(1) {
  animation-delay: 0s;
}
.upload .loading .wave:nth-child(2) {
  animation-delay: 1s;
}
.upload .loading .wave:nth-child(3) {
  animation-delay: 2s;
}


@keyframes rotate {
  0% {
    border-color:rgba(0,0,0,0.1);
  }
  50% {
    border-color:rgba(0,0,0,0.3);
  }
  100% {
    border-color:rgba(0,0,0,0.1);
  }
}


@keyframes pulsate {
  0% {
    background-color:rgba(0,0,0,0.05);
  }
  50% {
    background-color:rgba(0,0,0,0.1);
  }
  100% {
    background-color:rgba(0,0,0,0.05);
  }
}


.signature_table {
  border-collapse: collapse;
  width:100%;
}
.signature_table tr td {
  text-align:left;
  padding:10px;
  background-color:rgba(0,0,0,0.03);
  border-bottom: 1px solid #fff;
}

.signature_table tr:nth-child(odd) td {
}
.signature_table tr td:first-child {
  color:rgba(0,0,0,0.6);
  width:150px;
}
.signature_block {
  padding:5px;
  background-color:rgba(0,0,0,0.01);
  border-bottom: 2px solid #eee;
  box-shadow: 0px 0px 10px #ccc;
  margin: 10px 0px;
}
.signature_block .title{
  padding: 10px;
  margin:0px;
}
.card_wrapper {
  max-width:900px;
  margin: 0px auto;
}
.document_card
{
  box-sizing:border-box;
  background-color:#fefefe;
  max-width:900px;
  border: 1px solid rgba(0,0,0,0.05);
  
  
  /*box-shadow:0px 0px 5px #ccc;*/
  margin: 40px 40px;
  padding: 0px 20px 20px 20px;
  
  transition: all 1s;
  background-image:linear-gradient(-130deg, #eee, #fff);
}

.document_card.loading {
  background-color:#eee;
}
.document_card .loadingtext {
  width:30%;
  height:30px;
  background-color:rgb(235, 235, 235);
  border-radius:10px;
  
  animation: 2s pulsate linear infinite;

}
.document_card h1.loadingtext {
  width:30%;
  height:40px;
  background-color:rgb(235, 235, 235);
  border-radius:10px;
}
.document_card .signatures.loadingtext {
  width:100%;
  height:80px;
  background-color:rgb(235, 235, 235);
}

.document_card .warning-text {
  background-color:rgb(175, 115, 115);
  color:#fff;
  padding:10px;
  border-radius:5px;
  margin-top:10px;
}
.document_card .success {
  background-color:rgb(115, 175, 115);
  border-radius:5px;
  display:inline-block;
  padding:10px;
  color:#fff;
  margin-right:10px;
}
.document_card .fail {
  background-color:rgb(175, 115, 115);
  border-radius:5px;
  display:inline-block;
  padding:10px;
  margin-right:10px;
  color:#fff;
}
.document_card .checkmark {
  width:30px;
  height:30px;
  line-height:30px;
  border-radius:5px;
  padding:0px;
  text-align:center;
} 
.document_card .checkmark.success {
  background-color:rgb(115, 175, 115);
  line-height:32px;
  display:inline-block;
  color:#fff;
} 
/*
.document_card.docusign {
  background-image:url(/public/docusign.png);
  background-position: calc(100% - 10px) 10px;
  background-repeat: no-repeat;
  background-size: 130px 30px;
}

.document_card.assently {
  background-image:url(/public/assently.png);
  background-position: calc(100% - 10px) 10px;
  background-repeat: no-repeat;
  background-size: 132px 30px;
}
.document_card.scrive {
  background-image:url(/public/scrive.png);
  background-position: calc(100% - 10px) 10px;
  background-repeat: no-repeat;
  background-size: 118px 30px;
}*/

.docusign-logo {
  display: inline-block;
  width: 130px;
  height: 30px;
  filter:grayscale(1);
  opacity: 0.3;
  background-image:url(/public/docusign.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.assently-logo {
  width: 132px;
  height: 30px;
  display: inline-block;
  filter:grayscale(1);
  opacity: 0.3;
  background-image:url(/public/assently.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.scrive-logo {
  width: 118px;
  height: 30px;
  display: inline-block;
  filter:grayscale(1);
  opacity: 0.4;
  background-image:url(/public/scrive.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.scrive-logo {
  width: 118px;
  height: 30px;
  display: inline-block;
  filter:grayscale(1);
  opacity: 0.4;
  background-image:url(/public/scrive.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.admincontrol-logo {
  width: 199px;
  height: 25px;
  display: inline-block;
  filter:grayscale(1);
  opacity: 0.3;
  background-image:url(/public/admincontrol.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.verified-logo {
  width: 95px;
  height: 30px;
  display: inline-block;
  filter:grayscale(1);
  opacity: 0.3;
  background-image:url(/public/verified.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.signicat-logo {
  width: 55px;
  height: 30px;
  display: inline-block;
  filter:grayscale(1);
  opacity: 0.3;
  background-image:url(/public/signicat.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.wrapper-info-box {
  width:100%;
  background-color:#ccc;
}
.info-box {
  max-width:2000px;
  margin: auto;
  text-align:center;
}
.info-box .brand {
  margin:20px;
}
.document_card h2 {
  word-break: break-all;
}
.document_card.docusign:before {
  content: ' ';
  display: block;
  width: 130px;
  height: 30px;
  filter:grayscale(1);
  float:right;
  margin-top:30px;
  opacity: 0.3;
  background-image:url(/public/docusign.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}
.document_card.assently:before {
  content: ' ';
  display: block;
  width: 132px;
  height: 30px;
  filter:grayscale(1);
  float:right;
  margin-top:30px;
  opacity: 0.3;
  background-image:url(/public/assently.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}
.document_card.scrive:before {
  content: ' ';
  display: block;
  width: 118px;
  height: 30px;
  float:right;
  margin-top:30px;
  filter:grayscale(1);
  opacity: 0.3;
  background-image:url(/public/scrive.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}
.document_card.verified:before {
  content: ' ';
  display: block;
  width: 118px;
  height: 30px;
  float:right;
  margin-top:23px;
  filter:grayscale(1);
  opacity: 0.3;
  background-image:url(/public/verified.png);
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.document_card hr {
  border: 1px rgba(0,0,0,0.2);
  border-style: solid none none none;
}